/* Style tweaks */

html, body, .container-fluid, .row {
    height: 100%;
}

html, body {
    overflow-x: hidden;
}

/*main area not overlapped by header*/
body {
    padding-top: 70px;
}

/*options sidebar go all height*/
.sidebar {
    background-color: #333333;
    position: absolute;
    min-height: calc(100% - 20px);
}

/*for correct [hidden] attribute binding for angular*/
[hidden] {
    display: none !important;
}
